import firebase from "gatsby-plugin-firebase"
import { ICSVHeader, IRegisterResponse } from "./medicalDao"
import moment from "moment"
import {
  DisplayTimeType, INSTITUTION,
  MedicineReceiveType, PAYMENT_METHOD, RESERVE_STATUS,
  ReserveStatusType,
  ReserveTimeType,
  ReserveType
} from "../../../functions/src/types"
import { DisplayTimeKey } from "./firestore"
import { forEach } from "lodash"
import { getUser } from "./auth"


export interface IExportReserve {
  id: string,
  date: string,
  from: string,
  to: string,
  patient_name: string,
  gender: string,
  age: string,
  doctor_id: string,
  doctor_name: string,
  first: string,
  medical_id: string,
  medicine_receive_type: string,
  patient_id: string,
  payment_method: string,
  shipment_date: string,
  status: string,
  type: string,
  created_at: string,
  updated_at: string,
  amount: string,
  medical_fee: string,
  delivery_fee: string,
  np_fee: string,
  other_fee: string,
  system_fee: string,
  gmoTransactionId: string,
  shopTransactionId: string,
  charge_id: string,
  department_id: string,
  pharmacy_id: string,
  coupon?: string
  f_id?: string
  f_name?: string
  f_mail?: string
  f_tel?: string
}

export const ReserveExportCSVHeader: ICSVHeader[] = [
  { type: "string", label: "予約ID", key: "id", comment: "予約ID" },
  { type: "string", label: "予約日", key: "date", comment: "予約日" },
  { type: "string", label: "開始時刻", key: "from", comment: "開始時刻" },
  { type: "string", label: "終了時刻", key: "to", comment: "終了時刻" },
  { type: "string", label: "氏名", key: "patient_name", comment: "ご利用者様氏名" },
  { type: "string", label: "性別", key: "gender", comment: "性別" },
  { type: "string", label: "年齢", key: "age", comment: "年齢" },
  { type: "string", label: "医師ID", key: "doctor_id", comment: "医師ID" },
  { type: "string", label: "医師名", key: "doctor_name", comment: "医師名" },
  { type: "string", label: "初回診療", key: "first", comment: "初回診療" },
  { type: "string", label: "医療機関ID", key: "medical_id", comment: "医療機関ID" },
  { type: "string", label: "診療科ID", key: "department_id", comment: "診療科ID" },
  { type: "string", label: "お薬配送方法", key: "medicine_receive_type", comment: "お薬配送方法" },
  { type: "string", label: "患者ID", key: "patient_id", comment: "患者ID" },
  { type: "string", label: "お支払方法", key: "payment_method", comment: "お支払方法" },
  { type: "string", label: "出荷日", key: "shipment_date", comment: "出荷日" },
  { type: "string", label: "予約ステータス", key: "status", comment: "予約ステータス" },
  { type: "string", label: "予約タイプ", key: "type", comment: "予約タイプ" },
  { type: "string", label: "データ作成日", key: "created_at", comment: "データ作成日" },
  { type: "string", label: "データ更新日", key: "updated_at", comment: "データ更新日" },
  { type: "string", label: "合計金額", key: "amount", comment: "合計金額" },
  { type: "string", label: "診察料", key: "medical_fee", comment: "診察料" },
  { type: "string", label: "配送料", key: "delivery_fee", comment: "配送料" },
  { type: "string", label: "コンビニ後払い手数料", key: "np_fee", comment: "コンビニ後払い手数料" },
  { type: "string", label: "その他費用", key: "other_fee", comment: "その他費用" },
  { type: "string", label: "システム利用料", key: "system_fee", comment: "システム利用料" },
  { type: "string", label: "決済ID(クレカ）", key: "charge_id", comment: "決済ID(クレカ)" },
  { type: "string", label: "GMOトランザクションID", key: "gmoTransactionId", comment: "GMOトランザクションID" },
  { type: "string", label: "加盟店取引ID(決済ID）", key: "shopTransactionId", comment: "加盟店取引ID(決済ID）" },
  { type: "string", label: "関連薬局ID", key: "pharmacy_id", comment: "関連薬局ID" },
]

export const CouponReserveExportCSVHeader: ICSVHeader[] = [
  { type: "string", label: "予約ID", key: "id", comment: "予約ID" },
  { type: "string", label: "予約日", key: "date", comment: "予約日" },
  { type: "string", label: "開始時刻", key: "from", comment: "開始時刻" },
  { type: "string", label: "終了時刻", key: "to", comment: "終了時刻" },
  { type: "string", label: "氏名", key: "patient_name", comment: "ご利用者様氏名" },
  { type: "string", label: "性別", key: "gender", comment: "性別" },
  { type: "string", label: "年齢", key: "age", comment: "年齢" },
  // { type: "string", label: "医師ID", key: "doctor_id", comment: "医師ID" },
  // { type: "string", label: "医師名", key: "doctor_name", comment: "医師名" },
  // { type: "string", label: "初回診療", key: "first", comment: "初回診療" },
  // { type: "string", label: "医療機関ID", key: "medical_id", comment: "医療機関ID" },
  // { type: "string", label: "診療科ID", key: "department_id", comment: "診療科ID" },
  // { type: "string", label: "お薬配送方法", key: "medicine_receive_type", comment: "お薬配送方法" },
  // { type: "string", label: "患者ID", key: "patient_id", comment: "患者ID" },
  // { type: "string", label: "お支払方法", key: "payment_method", comment: "お支払方法" },
  // { type: "string", label: "出荷日", key: "shipment_date", comment: "出荷日" },
  // { type: "string", label: "予約ステータス", key: "status", comment: "予約ステータス" },
  // { type: "string", label: "予約タイプ", key: "type", comment: "予約タイプ" },
  // { type: "string", label: "データ作成日", key: "created_at", comment: "データ作成日" },
  // { type: "string", label: "データ更新日", key: "updated_at", comment: "データ更新日" },
  // { type: "string", label: "合計金額", key: "amount", comment: "合計金額" },
  // { type: "string", label: "診察料", key: "medical_fee", comment: "診察料" },
  // { type: "string", label: "配送料", key: "delivery_fee", comment: "配送料" },
  // { type: "string", label: "コンビニ後払い手数料", key: "np_fee", comment: "コンビニ後払い手数料" },
  // { type: "string", label: "その他費用", key: "other_fee", comment: "その他費用" },
  // { type: "string", label: "システム利用料", key: "system_fee", comment: "システム利用料" },
  // { type: "string", label: "決済ID(クレカ）", key: "charge_id", comment: "決済ID(クレカ)" },
  // { type: "string", label: "GMOトランザクションID", key: "gmoTransactionId", comment: "GMOトランザクションID" },
  // { type: "string", label: "加盟店取引ID(決済ID）", key: "shopTransactionId", comment: "加盟店取引ID(決済ID）" },
  // { type: "string", label: "関連薬局ID", key: "pharmacy_id", comment: "関連薬局ID" },
  { type: "string", label: "クーポンコード", key: "coupon", comment: "クーポンコード" },
  { type: "string", label: "紹介者ID", key: "f_id", comment: "予約ID" },
  { type: "string", label: "紹介者氏名", key: "f_name", comment: "予約日" },
  { type: "string", label: "紹介者アドレス", key: "f_mail", comment: "予約日" },
  { type: "string", label: "紹介者電話番号", key: "f_tel", comment: "開始時刻" },
]

interface IUserData {
  id: string
  data: firebase.firestore.DocumentData
}

//予約タイプを取得
export const getReserveType = (type: ReserveType) => {
  switch (type) {
    case 1:
      return "クリニック予約"
    case 2:
      return "薬局予約"
    case 3:
      return "来店予約"
    default:
      return ""
  }
}

//お薬配送方法を取得
export const getMedicineReceiveType = (type: MedicineReceiveType) => {
  switch (type) {
    case 0:
      return "通常配送"
    case 1:
      return "処方箋のみ"
    case 2:
      return "お急ぎ便"
    case 10:
      return "対面受取"
    default:
      return ""
  }
}

//お支払方法を取得
export const getPaymentMethod = (type: number) => {
  switch (type) {
    case 0:
      return "クレジットカード決済"
    case 1:
      return "コンビニ後払い決済"
    default:
      return ""
  }
}

//予約ステータスを取得
export const getReserveStatus = (type: ReserveStatusType) => {
  switch (type) {
    case 0:
      return "空"
    case 1:
      return "予約済み"
    case 2:
      return "待合室待機中"
    case 3 :
      return "医師待機中"
    case 4 :
      return "開始"
    case 5 :
      return "診察終了"
    case 70 :
      return "待合室から退室（今すぐ診療のみ使用）"
    case 80 :
      return "利用者によってキャンセル"
    case 81 :
      return "医療機関によってキャンセル"
    case 82 :
      return "システムによってキャンセル"
    case 90 :
      return "支払い済み"
    case 91 :
      return "支払い失敗"
    default:
      return ""
  }
}

export const getReserveInformationByCSV = async (firestore: firebase.firestore.Firestore | undefined, functions: firebase.functions.Functions | undefined, dateFrom: Date | null, dateTo: Date | null, setProgressNum: any) => {
  let retVal: IExportReserve[] = []
  try {
    console.log(dateFrom)
    console.log(moment(dateFrom).valueOf())
    console.log(dateTo)
    console.log(moment(dateTo).valueOf())
    if (firestore) {
      setProgressNum(0)
      // const usersSnapShot = await firestore.collection("private").where("mileage_no",'<', '\uf8ff').where("type",'==', 7).get()
      const reservesQuery = await firestore.collection("reserve")
        .where("date", '>=', moment(dateFrom).startOf("day").valueOf())
        .where("date", '<=', moment(dateTo).startOf("day").valueOf())
      let reserveLastDoc = null;
      let hasNextPage = true;
      setProgressNum(5)

      // const users : IUserData[] = usersSnapShot.docs.map(doc=> ({
      //   id:doc.id,
      //   data:doc.data()
      // }))

      let i = 0
      while (hasNextPage) {
        // @ts-ignore
        const { docs, nextPageExists } = await getDocByLimit(reservesQuery, reserveLastDoc, 1000);
        if (docs.length > 0) {
          forEach(docs, (reserve) => {
            i++
            const reserve_data = reserve.data()
            // let user_data : any = users.find(user => user.id == reserve_data.patient_id)
            if (reserve_data) {
              retVal.push({
                id: reserve.id,
                date: moment(reserve_data.date).format("YYYY年MM月DD日"),
                from: DisplayTimeKey(reserve_data.from),
                to: DisplayTimeKey(reserve_data.to),
                patient_name: reserve_data.patient_name ?? "",
                gender: reserve_data.gender === 1 ? "男性" : "女性",
                age: reserve_data.age ?? "",
                doctor_id: reserve_data.doctor_id ?? "",
                doctor_name: reserve_data.doctor_name,
                first: reserve_data.first ? "初診" : "再診",
                medical_id: reserve_data.medical_id ?? "",
                department_id: reserve_data.department_id ?? "",
                medicine_receive_type: getMedicineReceiveType(reserve_data.medicine_receive_type),
                patient_id: reserve_data.patient_id ?? "",
                payment_method: getPaymentMethod(reserve_data.payment_method),
                shipment_date: reserve_data.shipment_date ?? "",
                status: getReserveStatus(reserve_data.status),
                type: getReserveType(reserve_data.type),
                created_at: moment(reserve_data.created_at.seconds * 1000).format("YYYY/MM/DD HH:mm:ss"),
                updated_at: moment(reserve_data.updated_at.seconds * 1000).format("YYYY/MM/DD HH:mm:ss"),
                amount: reserve_data.result?.amount ?? "",
                medical_fee: reserve_data.result?.medical_fee ?? "",
                delivery_fee: reserve_data.result?.delivery_fee ?? "",
                np_fee: reserve_data.result?.np_fee ?? "",
                other_fee: reserve_data.result?.other_fee ?? "",
                system_fee: reserve_data.result?.system_fee ?? "",
                charge_id: reserve_data.result?.charge_id ?? "",
                gmoTransactionId: reserve_data.result?.gmoTransactionId ?? "",
                shopTransactionId: reserve_data.result?.shopTransactionId ?? "",
                pharmacy_id: reserve_data.pharmacy_id ?? "",
              })
            }
          })
          // 最後のドキュメントを保持
          reserveLastDoc = docs[docs.length - 1];
        }
        hasNextPage = nextPageExists;
      }
    }
  } catch (e) {
    console.log(e)
  }

  return retVal
}

async function getDocByLimit(query: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>, startAfterDoc: any, batchSize: number) {
  // startAfterDocを使用して次のページを取得
  let batchQuery = query.limit(batchSize);
  if (startAfterDoc) {
    batchQuery = batchQuery.startAfter(startAfterDoc);
  }

  const batchSnapshot = await batchQuery.get();
  const docs = batchSnapshot.docs;
  const nextPageExists = docs.length === batchSize;

  return { docs, nextPageExists };
}

export const getCouponReserveInformationByCSV = async (firestore: firebase.firestore.Firestore | undefined, functions: firebase.functions.Functions | undefined, dateFrom: Date | null, dateTo: Date | null, setProgressNum: any) => {
  let retVal: IExportReserve[] = []
  try {
    if (firestore) {
      setProgressNum(0)
      const reservesQuery = await firestore.collection("reserve")
        .where("date", '>=', moment(dateFrom).startOf("day").valueOf())
        .where("date", '<=', moment(dateTo).startOf("day").valueOf())
        .where("status", '==', RESERVE_STATUS.PAID)
      let reserveLastDoc = null;
      let hasNextPage = true;
      setProgressNum(5)

      let i = 0
      while (hasNextPage) {
        // @ts-ignore
        const { docs, nextPageExists } = await getDocByLimit(reservesQuery, reserveLastDoc, 1000);
        if (docs.length > 0) {
          for (const reserve of docs) {
            i++
            const reserve_data = reserve.data()
            if (reserve_data && !!reserve_data.coupon && reserve_data.result.system_fee == 0 && !reserve_data.subscribed) {
              const friend = await firestore.collection("friend_coupon").where("code", '==', reserve_data.coupon).get()
              if (friend.docs.length > 0) {
                const friend_id = friend.docs[0].id
                const friend_data_get = await firestore.collection("private").doc(friend_id).get()
                const friend_data = friend_data_get.data()
                if (friend_data) {
                  retVal.push({
                    id: reserve.id,
                    date: moment(reserve_data.date).format("YYYY年MM月DD日"),
                    from: DisplayTimeKey(reserve_data.from),
                    to: DisplayTimeKey(reserve_data.to),
                    patient_name: reserve_data.patient_name ?? "",
                    gender: reserve_data.gender === 1 ? "男性" : "女性",
                    age: reserve_data.age ?? "",
                    doctor_id: reserve_data.doctor_id ?? "",
                    doctor_name: reserve_data.doctor_name,
                    first: reserve_data.first ? "初診" : "再診",
                    medical_id: reserve_data.medical_id ?? "",
                    department_id: reserve_data.department_id ?? "",
                    medicine_receive_type: getMedicineReceiveType(reserve_data.medicine_receive_type),
                    patient_id: reserve_data.patient_id ?? "",
                    payment_method: getPaymentMethod(reserve_data.payment_method),
                    shipment_date: reserve_data.shipment_date ?? "",
                    status: getReserveStatus(reserve_data.status),
                    type: getReserveType(reserve_data.type),
                    created_at: moment(reserve_data.created_at.seconds * 1000).format("YYYY/MM/DD HH:mm:ss"),
                    updated_at: moment(reserve_data.updated_at.seconds * 1000).format("YYYY/MM/DD HH:mm:ss"),
                    amount: reserve_data.result?.amount ?? "",
                    medical_fee: reserve_data.result?.medical_fee ?? "",
                    delivery_fee: reserve_data.result?.delivery_fee ?? "",
                    np_fee: reserve_data.result?.np_fee ?? "",
                    other_fee: reserve_data.result?.other_fee ?? "",
                    system_fee: reserve_data.result?.system_fee ?? "",
                    charge_id: reserve_data.result?.charge_id ?? "",
                    gmoTransactionId: reserve_data.result?.gmoTransactionId ?? "",
                    shopTransactionId: reserve_data.result?.shopTransactionId ?? "",
                    pharmacy_id: reserve_data.pharmacy_id ?? "",
                    coupon: reserve_data.coupon ?? "",
                    f_id: friend.docs[0].id,
                    f_name: friend_data.name,
                    f_mail: await getMail(functions, friend.docs[0].id),
                    f_tel: "'" + friend_data.tel,
                  })
                }
              }
            }
          }
          // 最後のドキュメントを保持
          reserveLastDoc = docs[docs.length - 1];
        }
        hasNextPage = nextPageExists;
      }
    }
  } catch (e) {
    console.log(e)
  }

  return retVal
}

const getMail = async (functions: firebase.functions.Functions | undefined, target_id: string) => {
  let mail = ""
  if (functions) {
    console.log("mail start")
    const func = functions.httpsCallable("getUserEmail")
    await func({ patientId: target_id}).then((data) => {
      mail = data.data
      console.log("data", data)
    }).catch(e => {
      console.log(e)
    })
    console.log("mail end")
  }
  return mail
}
